import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox"];

  selectAll(event) {
    const checked = event.target.checked;
    this.checkboxTargets.forEach((checkbox) => (checkbox.checked = checked));
  }
}
